.ant-picker-body {
  .ant-picker-content {
    user-select: none !important;
    tr {
      display: flex;
      gap: 20px;

      td {
        width: 60px;
        height: 52px;
        // background: green;
        display: flex;
        align-items: center;
        justify-content: center;

        .active_day {
          height: 100%;
          min-width: 40px;
          min-height: 45px;
          border-radius: 8px;
          // border: 1px solid #dada;
          background-color: #06c23a !important;
          color: #fff;
          padding: 0 3px !important;

          .day {
            border-bottom: 1px solid #fff !important;
          }
          .price_day_calendar {
            font-size: 11px;
          }
        }
        .normal_day {
          height: 100%;
          min-width: 40px;
          min-height: 45px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    // .ant-picker-cell {
    //   .day {
    //     background-color: red;
    //     // height: 50px;
    //     // width: 50px;
    //   }
    // }
  }
}

.ant-picker-panel-container {
  width: 370px;
  .ant-picker-panel {
    width: 100%;
    .ant-picker-date-panel {
      width: 100%;
    }
    .ant-picker-header {
      width: 100%;
      .ant-picker-header-super-next-btn,
      .ant-picker-header-super-prev-btn {
        display: none !important;
      }
    }
    .ant-picker-body {
      width: 100%;
      .ant-picker-content {
        width: 100%;
      }
    }
  }
}

.calendar_multiple_antd {
  width: 200px !important;
  .space_calendar {
    position: relative;
    .open_calendar {
      position: absolute;
      top: 0;
      width: 200px !important;
    }
  }
}
.range_absolute_btn {
  //   position: absolute;
  // z-index: 9;
  //   width: 200px;
  height: 28px;
  margin-top: 8px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #06c23a;
  color: #06c23a;

  font-size: 12px !important;
  background-color: white;
  border-radius: 6px;
  padding: 0 8px;
  cursor: pointer;
  &:hover {
    color: #06c23a !important;
    background-color: #fff !important;
    border: 1px solid #06c23a !important;
    transition: none !important;
  }
}
.calendar_multiple {
  height: 43px;
}
