.brokers-card {
  display: flex;
  padding: 12px 24px;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
  border-radius: 12px;
  background: #fff;
  .img_s {
    display: flex;
    align-items: center;
    @media only screen and (max-width: 768px) {
      width: 100% !important;
      justify-content: center;
    }
  }
  .broker-logo {
    border-radius: 12px;
    width: 262px;
    height: 245px;
  }

  .content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;

    .title {
      margin-top: 22px;
      color: #1b1c57;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      text-transform: capitalize;
      text-align: left;
    }

    .subtitle {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 4px;
      color: #1d4ed8;
      font-size: 10.5px;

      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }

    .description {
      color: #1b1c57;
      font-size: 15px;
      width: 100%;
      font-style: normal;
      font-weight: 600;
      line-height: 22.5px;
      opacity: 0.75;
    }

    .more-info-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
      width: 100%;

      .btn {
        padding: 12px;
        border-radius: 9px;
        background: #04c23a;
        color: #fff;
        font-size: 12px;

        font-style: normal;
        font-weight: 700;
        line-height: 16.5px;
        border: 0;
        cursor: pointer;
      }

      .contact-info {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 12px;

        .contact-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          width: 36px;
          height: 36px;
          border: 0;
          border-radius: 9px;
          background: #eaf4ee;
          padding: 6px;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    display: grid;
    justify-content: center;
  }
}
