.filter-container {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 9px;
  background: #fff;
  width: 300px;
  background-color: #fff;
  // width: 98%;

  .title {
    color: #1b1c57;
    font-size: 15px;

    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    white-space: nowrap;
  }

  .collapse-btn {
    width: 100%;
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 9px;
    border: 0.75px solid #04c23a;
    background: #fff;
    cursor: pointer;
    color: #04c23a;
    font-size: 14px;

    font-style: normal;
    font-weight: 700;
    line-height: 22px;

    .flip-arrow {
      transform: rotate(180deg);
    }
  }

  .filters-content-container {
    width: 250px;
    width: 97%;
  }
}
